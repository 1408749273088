import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { PrincipalContainer } from '../../components/containers/principalContainer';
import parse from 'html-react-parser';
import './index.css'
import iliana1 from './fotosIliana/iliana-1.jpeg'
import ileanaExtra1 from './fotosIliana/ileanaExtra1.jpeg'
import ileanaExtra2 from './fotosIliana/ileanaExtra2.jpeg'
import ileanaExtra3 from './fotosIliana/ileanaExtra3.jpeg'
import ileanaExtra4 from './fotosIliana/ileanaExtra4.jpeg'
import ileanaExtra5 from './fotosIliana/ileanaExtra5.jpeg'
import ileanaExtra6 from './fotosIliana/ileanaExtra6.jpeg'
import ileanaExtra7 from './fotosIliana/ileanaExtra7.jpeg'


const Noticia = () => {
    const [noticiaData, setNoticiaData] = useState({
        titulo:'',
        pathTitulo:'',
        contenido:[],
        imagenPrincipal:'',
        imagenesExtra:[],
        showInfo:'',
        noticias:'',
    })
    const {noticiaNombre} = useParams()

    console.log(noticiaNombre)

    useEffect(() => {
       if( noticiaData.titulo==="hola") window.location.replace('https://admision.tsj.mx')
    }, [])

    useEffect(() => {

        const url = 'https://developer.tecmm.mx:3324/getPrueba'
        axios.get(url).then(response => response.data)
        .then((data) => {
    
    
          for(var i=0; i<data.length; i++){
            if(data[i].pathTitulo == noticiaNombre){
              //console.log(JSON.parse(data[i].contenido))
              setNoticiaData({
                titulo:data[i].titulo,
                pathTitulo:data[i].pathTitulo,
                contenido:JSON.parse(data[i].contenido),
                imagenPrincipal:data[i].imagenPrincipal,
                imagenesExtra:JSON.parse(data[i].imagenesExtra),
                showInfo:"none",
                noticias:data,
              })
            }else{
    
            }
          }
    
        })

    }, [])


    // [
    //     {"id":0,"tipo":"parrafo","texto":"El pasado 30 de mayo de 2023 en las instalaciones que ocupa la DirecciÃ³n General de nuestra instituciÃ³n, se acordÃ³ la designaciÃ³n de tres nuevos directores para las Unidades AcadÃ©micas de Chapala, Tamazula y Tequila, misma que fue autorizada por unanimidad de los consejeros de la H. Junta de Gobierno, quienes revisaron las propuestas, para finalmente designar a estos nuevos directores:"},
    //     {"id":1,"tipo":"directorCard","texto":`<div style="text-align: center; background-color: pink; width:300px"> <img style="width:300px" src="https://cdn.dribbble.com/users/304574/screenshots/6222816/male-user-placeholder.png"> <h3 style="font-weight: bolder;">Eduardo Daniel Miramontes</h3> <h3 style="font-style:italic; font-weight: 300; margin: auto">Eduardo Daniel Miramontes</h3> </div>`},
    //     {"id":2,"tipo":"parrafo","texto":"Doctor en Ciencias en FÃ­sica, cuenta con mÃ¡s de 25 aÃ±os de trabajo en el Ã¡mbito de la educaciÃ³n superior como Director General en diversos campus de TecnolÃ³gico de Monterrey."},
    //     {"id":3,"tipo":"parrafo","texto":"Maestra en AdministraciÃ³n y GestiÃ³n Regional, tiene mÃ¡s de 10 aÃ±os de experiencia en el TecnolÃ³gico de Tamazula. Cuenta con una amplia experiencia laboral en el Ã¡mbito del emprendimiento y la innovaciÃ³n."},{"id":3,"tipo":"parrafo","texto":"Doctorante en DirecciÃ³n de Proyectos, colaborÃ³ desde el aÃ±o 2006 en el ITS de Tequila, tambiÃ©n se ha desempeÃ±ado en la DirecciÃ³n AcadÃ©mica de la UTZMG, y conoce ampliamente el Sistema TecnolÃ³gico de Jalisco."},
    //     {"id":4,"tipo":"parrafo","texto":"Sin duda, estos cambios atienden ante todo a la consolidaciÃ³n acadÃ©mica del TecnolÃ³gico Superior de Jalisco"}
    // ]

  return (
    <PrincipalContainer  showSquares={true}>

        {noticiaData.titulo === "El Tecnológico Superior de Jalisco recibe a su nueva Directora General." ? 
        
    
        <div className="noticiaContainer">

        <div className="noticiaData">
            <div className="titleContainer">
                <h1 className="h1-tituloNoticia">El Tecnológico Superior de Jalisco recibe a su nueva Directora General.</h1>
                <img className="img-imagenPrincipal" src={iliana1}/>
            </div>
            
            <div className="contenidoNoticia">

            <p style={{fontStyle:"italic"}}>Zapopan, Jalisco, Jueves 18 de enero de 2024</p>

            <p>Iliana Janett Hernández Partida fue nombrada por el Gobernador del Estado de Jalisco como la nueva Directora General del Tecnológico Superior de Jalisco.</p>

            <p>Este jueves 18 de enero de 2024, se llevó a cabo la toma de protesta y presentación de <a style={{fontWeight:"bold"}}>Iliana Janett Hernández Partida</a> como Directora General del Tecnológico Superior de Jalisco, en sustitución de José Rosalío Muñoz Castro, quien pasa a ocupar nuevamente el cargo de Subsecretario de Educación Superior de Jalisco. La nueva titular fue presentada por Alfonso Pompa Padilla; Secretario de Innovación, Ciencia y Tecnología de Jalisco, donde también estuvo presente dentro del presídium Germán Tinajero González; Secretario General del Sindicato Mayoritario.</p>
            
            <p>La Maestra Iliana Janett Hernández Partida hace historia en nuestra Institución, ya que se convierte en la primera mujer Directora General, además, es bien conocida por toda la comunidad #TecSJ, ya que ha trabajado de cerca con nuestro Instituto desde su conformación, primero como Directora de Organismos Públicos Descentralizados de Educación Superior y posteriormente como Subsecretaria de Educación Superior, por lo que conoce bien las necesidades y áreas de oportunidad de nuestra Institución.</p>
            
            <p style={{fontStyle:"italic", marginLeft:"4vh"}}>“Agradezco la oportunidad de la vida en coincidir con personas que amamos lo que hacemos: la educación superior… </p>

            <p style={{fontStyle:"italic", marginLeft:"4vh"}}>…A mi me gustaría que tengamos muy claro que cuando todos queremos, juntos podemos, yo vine a sumar, yo vine a velar por el bienestar de las y los estudiantes y de todos los colaboradores de esta Institución… </p>

            <p style={{fontStyle:"italic", marginLeft:"4vh"}}>… El día de hoy me comprometo públicamente por ese amor por servicio apasionado que me caracteriza en todas las encomiendas que hasta este momento se me han encauzado.”</p>

            <p>La nueva Directora General fue presentada a toda la red TecSJ de Unidades Académicas en Jalisco a través de medios digitales transmitidos en vivo, donde se destacó su excelente labor y compromiso en pro de nuestra institución.</p>
            
            <p>Mira la transmisión en vivo del evento aquí: <a href="https://www.facebook.com/TecSJ/videos/755660086444350">https://www.facebook.com/TecSJ/videos/755660086444350</a> </p>
            
            </div>
            {/* <div className="contenidoNoticia">
                {noticiaData.contenido.map((it)=>(
                it.tipo == "parrafo"?(
                    <p className="p-subtituloNoticia">{parse(it.texto)}</p>
                    
                ):it.tipo === "directorCard"?(
                    
                    <div className='cardNewDirectorContainer'>
                        <img style={{width:"200px"}} src={it.imgDirector}/>
                        <h3>{it.nameDirector}</h3>
                        <h4>{it.puestoDirector}</h4>
                    </div>
                )
                :
                (
                    <h2 className="h2-subtituloNoticia">{it.texto}</h2>
                )
                ))}
            </div> */}

        <div className="div-imagenesExtra">

                <img src={ileanaExtra1}/>
                <img src={ileanaExtra2}/>
                <img src={ileanaExtra3}/>
                <img src={ileanaExtra4}/>
                <img src={ileanaExtra5}/>
                <img src={ileanaExtra6}/>
                <img src={ileanaExtra7}/>


        </div>
        </div>

        {/* <div className="noticiaTimeline">
        <h1>MAS NOTICIAS</h1>
        <div className="noticias">
            {this.state.noticias.map((it)=>(
            <div className="timelineItem">
                <h1>{it.titulo}</h1>
                <a>ver mas<i class="arrow right icon"></i></a>
            </div>
            ))}
        </div>
        </div> */}

</div>
    
        :

        <div className="noticiaContainer">

            <div className="noticiaData">
                <div className="titleContainer">
                    <h1 className="h1-tituloNoticia">{noticiaData.titulo}</h1>
                    <img className="img-imagenPrincipal" src={noticiaData.imagenPrincipal}/>
                </div>

                <div className="contenidoNoticia">
                    {noticiaData.contenido.map((it)=>(
                    it.tipo == "parrafo"?(
                        <p className="p-subtituloNoticia">{parse(it.texto)}</p>
                        
                    ):it.tipo === "directorCard"?(
                        
                        <div className='cardNewDirectorContainer'>
                            <img style={{width:"200px"}} src={it.imgDirector}/>
                            <h3>{it.nameDirector}</h3>
                            <h4>{it.puestoDirector}</h4>
                        </div>
                    )
                    :
                    (
                        <h2 className="h2-subtituloNoticia">{it.texto}</h2>
                    )
                    ))}
                </div>

            <div className="div-imagenesExtra">
                {noticiaData.imagenesExtra.map((it, key)=>(
                it == "Hubo un error al subir el archivo!"?(
                    <div>
                    </div>
                ):(
                    <img src={it}/>
                )
                ))}
            </div>
            </div>

            {/* <div className="noticiaTimeline">
            <h1>MAS NOTICIAS</h1>
            <div className="noticias">
                {this.state.noticias.map((it)=>(
                <div className="timelineItem">
                    <h1>{it.titulo}</h1>
                    <a>ver mas<i class="arrow right icon"></i></a>
                </div>
                ))}
            </div>
            </div> */}

            </div>


        }



    </PrincipalContainer>
    
  )
}

export default Noticia