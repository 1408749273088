import React, { useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './index.css'
import { PrimaryButton } from '../buttons';
import { ModalEstimuloDocente } from '../modal/modalEstimuloDocente';

export const CarouselLanding = () => {

  const [modalGacetaShow, setModalGacetaShow] = useState(false);
  return (
    <div className='carouselLandingContainer'>

      <ModalEstimuloDocente
        show={modalGacetaShow}
        onHide={() => setModalGacetaShow(false)}
      />
      



      <Carousel
        width="100%"
        showIndicators={false}
        showStatus={false}
        showArrows={true}
        showThumbs={false}
        autoPlay
        infiniteLoop
        emulateTouch
        className='carouselLandingComponent'
      >

        <a target='_blank' href='https://sites.google.com/tecmm.edu.mx/simposiodeinvestigacion2024/inicio?pli=1'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/SIMPOSIO.jpg" />
          </div>
        </a>

        <a target='_blank' href='https://tecmm.edu.mx/documentos/2024/theAdventureOfLearningALanguage.pdf'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/theAdventureOfLearningALanguage.jpg" />
          </div>
        </a>


        <a target='_blank' href='https://www.facebook.com/share/v/7FfCRtPtrAFspZzW/'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/banner_aniversario.jpg" />
          </div>
        </a>

        <a href='https://tecmm.edu.mx/documentos/2024/condiciones-generales-trabajo-tsj-2024.pdf'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/condiciones-2024.jpeg" />
          </div>
        </a>

        <a href='https://cps.seajal.org/convocatoria-concurso-fotografico/'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/banner_integridad.jpg" />
          </div>
        </a>

        <a href='https://revista.scientiatecnologica.com.mx/index.php/inicio/index'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/revistaSienciaTecnologica.jpeg" />
          </div>
        </a>

        <a href='https://enlinea.tsj.mx/jornada/'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/jornada_transformacion_digital.png" />
          </div>
        </a>

        <a href='https://enlinea.tsj.mx/labs/'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/cloud_labs.jpeg" />
          </div>
        </a>

        <a href='https://tecmm.edu.mx/documentos/normatividad2023/reglamento_estudiantil.pdf'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/reglamentoEstudiantil.png" />
          </div>
        </a>

        <a href='https://tecmm.edu.mx/documentos/2024/convocatoria_Proyectos_Investigacion_2024.pdf'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/convocatoriaProyectosInvestigacion.jpg" />
          </div>
        </a>

        <a href='https://mexico-u.talento-cloud.com/'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/impulsandoTalento.jpg" />
          </div>
        </a>

        <a href='http://tecmm.edu.mx/estimuloDocente'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/estimuloDocente.jpg" />
          </div>
        </a>

        <a target='_blank' href='https://heyzine.com/flip-book/3a5ff46d5b.html#page/64'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/gaceta2023.jpeg" />
          </div>
        </a>


        <a href='https://tecmm.edu.mx/Noticia/El-Tecnologico-Superior-de-Jalisco-recibe-a-su-nueva-Directora-General.'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/bannerIliana.jpg" />
          </div>
        </a>

{/* 
        <a href='https://tecmm.edu.mx/diplomadoDocente'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/banner_diplomado.jpg" />
          </div>
        </a> */}


        <a href='https://www.thincrs.com/artesanos-de-la-educacion'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/hub.jpg" />
          </div>
        </a>



        <a href='https://tecmm.edu.mx/Noticia/El-TecSuperiorJalisco-interconecta-con-el-Sistema-de-Descarga-de-T%C3%ADtulos-Electr%C3%B3nicos.' target='_blank'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/notaTitulos.jpg" />
          </div>
        </a>
        


        <a href='http://mi.tecmm.mx:3010' target='_blank'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/inscripciones_2023.jpg" />
          </div>
        </a>

        <a href='https://www.jalisco.gob.mx/es/gobierno/comunicados/protocolo-cero-para-prevenir-atender-sancionar-y-erradicar-el-acoso-y' target='_blank'>
          <div>
            <img src="https://tecmm.edu.mx/img/carousel/manifiesto.jpg" />
          </div>
        </a>
      </Carousel>

      <div className='carouselButtonsContainer'>
        <PrimaryButton color='#33179c' text='Unidades Académicas de Jalisco' link='/UnidadesAcademicas' />
        &nbsp;
        &nbsp;
        <PrimaryButton color='#33179c' text='Oferta Académica' link='/OfertaAcademica'/>
      </div>
      
    
    </div>

  )
}
